import React, { Component } from 'react'
import { Helm } from '../components/header'
import Container from 'react-bootstrap/Container'
import { Item, Slider } from '../components/main'
import { Col,Row } from 'react-bootstrap'
import Alert from 'react-bootstrap/Alert'
import '../style/gold2.css'
import path from '../assets/img/humayri/path1.svg'
import music from '../assets/music/dzaky.mp3'
import logoig from '../assets/img/humayri/logoig.svg'
import path3 from '../assets/img/gold1/path3.svg'
import path2 from '../assets/img/gold1/path2.svg'
import bunga6 from '../assets/img/bunga6.png'
import logo from '../assets/img/logo.ico'
import { gambar } from '../params'
import AOS from 'aos';
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import post from '../params/post'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { cap } from '../params'


let cmain="#171717"


export default class Halo extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef()
    this.nama = React.createRef()
    this.alamat = React.createRef()
    this.pesan = React.createRef()

    this.state = {
      acara: [],
      days: '00',
      minutes: '00',
      hours: '00',
      seconds: '00',
      hide: true,
      clicked: 0,
      sesi: 1,
      hadir: false
    }
  }

  componentDidMount() {
    AOS.init({
      // initialise with other settings
      duration: 1000
    });

    var countDownDate = new Date("12/31/2020").getTime();

    // Update the count down every 1 second
    var x = setInterval(() => {

      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      this.setState({
        days: days, hours: hours, minutes: minutes, seconds: seconds
      })
    }, 1000);

  }
  play = () => {
    AOS.refresh()
    var snd = new Audio(music);
    snd.type = 'audio/mp3';
    snd.play();

    window.scrollTo({
      top: this.myRef.current.offsetTop,
      behavior: 'smooth'
    })
    this.setState({ hide: false })
    setTimeout(() => {
      var elmnt = document.getElementById('sectiongold24');
      elmnt.scrollIntoView();
    }, 1000)
    setTimeout(() => {
      AOS.refresh()
    }, 1100)

  }
  googleCalendar = () => {
    window.open(`https://calendar.google.com/event?action=TEMPLATE&tmeid=NnR1ZTl2MmJvN3J2dDljbG11djFiZWY1OHQgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com`, '_blank')
  }

  handleSubmit = async () => {
    let local = localStorage.getItem('pesan')
    let err = []
    if (this.nama.current.value == "") {
      err.push('Nama tidak Boleh Kosong')
    }
   
    if (this.pesan.current.value == "") {
      err.push('Pesan tidak Boleh Kosong')
    }
    if (err.length == 0) {
      confirmAlert({
        message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
        buttons: [
          {
            label: 'Yes',
            onClick: async () => {
              let send = await post(
                ` dari: "${this.nama.current.value}", hadir: "${this.state.hadir}", jumlahhadir: "", kepada: "dzaky-karina", pesan:"${this.pesan.current.value}",alamat: "${this.alamat.current.value}"`
              )
            if (send.status == 200) {
                this.setState({ submitted: true })
                localStorage.setItem('pesan', this.pesan.current.value)
                this.nama.current.value = ''
                this.pesan.current.value = ''
            }else{
                err.push('Koneksi Gagal')
            }
              
            }
          },
          {
            label: 'No',
            onClick: () => { }
          }
        ]
      });
    } else {
      this.setState({ err: err, submitted: false })
    }



  }
  useQuery = () => {
    return new URLSearchParams(this.props.location.search);

  }

  render() {
    let slide = ["	https://i.ibb.co/6mLBzvH/IMG-7474-compressed.jpg	",
    "	https://i.ibb.co/t8PXP3j/10.jpg	",
    "	https://i.ibb.co/9g73mSC/9.jpg	",
    "	https://i.ibb.co/qBS9vBF/7.jpg	",
    "	https://i.ibb.co/ygKqYJz/6.jpg	",
    "	https://i.ibb.co/phCQztW/5.jpg	",
    "	https://i.ibb.co/2SHZR8D/4.jpg	",
    "	https://i.ibb.co/7WJGTmk/3.jpg	",
    "	https://i.ibb.co/MnqGrxs/2.jpg	",
    "	https://i.ibb.co/hV13qyx/1.jpg	",    
    ]
    let slider = []
    slide.map(v => {
      slider.push(gambar(v, 95,'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=625&h=425'))
    })
    let query = this.useQuery().get('name');
    query = query ? cap(query) : ''
    let { clicked, acara, days, hours, minutes, seconds, hide, sesi, hadir, err, submitted } = this.state

    return (
      <>
        <Helm
          title='Undanganku - Dzaky & karina'
          desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
          logo={logo}
        />
        <div id='gold2' style={{ backgroundColor: cmain }}>
          <div className='w-100' style={{
            overflow: 'hidden', maxWidth: '100vw'
          }}>
            <div className="jumbotrongold2 jumbotron-fluid mb-0 w-100 pt-5 position-relative" id='sectiongold21' style={{
              overflow: 'hidden', maxWidth: '100vw',
              backgroundImage: `url(${gambar('https://i.ibb.co/S7DnSRS/Modal-Fix.jpg',
                95)})`
            }}>
              <Row className="position-absolute" style={{bottom:0,left:0}}>
              <div className={` path w-100 ${hide ? 'hide' : 'show'}`}>
                <img src={path} className='img-fluid w-100' />
              </div>
              </Row>
              <div className="container" >
                <Item>
                  <Col xs={12} md={4}>
                    <img className='img-fluid w-100' src={gambar('https://i.ibb.co/nbJfnzr/Logo.png')} />
                  </Col>
                </Item>
                <Item>
                  {query ? (
                    <h2 className={`col-md-4 roboto-slab text-center pt-5 pt-md-3`}> Kepada : {query} </h2>

                  ) : false}</Item>
                {!hide ? false : (
                  <div className='tombol'>
                    <Item>
                      <button
                        onClick={() => {
                          this.play()
                        }}
                        style={{
                          marginTop: !query ? '100px' : 0
                        }}
                      >Open Invitation</button>
                    </Item>
                  </div>
                )}
              </div>
            </div>
            <div className={hide ? 'd-none' : 'd-block'} style={{
              backgroundImage:`url('http://static.allbackgrounds.com/bg/black-pattern.jpg')`,
              
           }}>
             
             
              <Container id='sectiongold24'  ref={this.myRef} 
              >
                {/* <div className='path w-100' >
                  <img src={path3} className='img-fluid w-100' />
                </div> */}
                <div className='pt-5 pb-5'>

                  <Item>
                    <Col xs={4} lg={1}>
                      <img src={path2} className='img-fluid w-100' data-aos="fade-left" data-aos-duration="1000" />
                    </Col>
                  </Item>
                  <Item>
                    <p className='text-center col-md-8 quotes pt-3' data-aos="fade-left"
                      data-aos-duration="1000">
                     Menikah adalah jatuh cinta pada orang yang sama setiap harinya. Engkau mengetahui ia tak sempurna, dan ia pun tahu kau tak sempurna, namun tetap memilih bersama
                       <br /><br />
                    </p>
                  </Item>

                </div>
              </Container>
              <Container fluid>
                <Item>
                  <Col xs={12} md={8}>
                    <div className='opening pb-md-4' data-aos="zoom-in">
                      <p style={{ color: 'white' }} className='text-center poppins font18'>
                        Maha Suci Allah yang telah menciptakan makhluk-Nya secara Berpasang-pasangan.
                        Ya Allah perkenankan kami merangkai kasih sayang yang kau ciptakan diantara Putra Putri kami.
                      </p>
                    </div>
                  </Col>
                </Item>

              </Container>
              <Container id='mempelai' fluid className='pb-5'>
                <Item>
                  <Col xs={12} md={10}>
                    <Item>
                      <div className=' col-xs-12 col-md-5' data-aos="fade-left" data-aos-duration="1000" >
                        <div className='kotak mr-lg-2'
                          style={{ backgroundColor: cmain }}>
                          <Item>
                            <h1 style={{ fontSize: '1.75rem', color: '#F0C04F',lineHeight:1.2 }} className="w-100 text-center">
                            Dzaky Alpin <br/>Kurniawan
                            </h1>
                          </Item>
                          <Item>
                            <Col xs={6}>
                              <img src={gambar('https://i.ibb.co/859VGWq/cowok.png')} className='img-fluid w-100 rounded' />
                            </Col>
                          </Item>
                          <Item>
                            <p className='text-center' style={{ fontSize: '1rem' }}>
                              Putra dari :<br />
                              Bapak H. Suganda <br />
                              dan<br />
                              Ibu Hj. Anita Rahayu Ningsih (almh.)<br />
                            </p>
                          </Item>
                          <Item>
                          <Col xs={3}>
                              <img src={logoig} className='img-fluid w-100 btn p-0' onClick={() => {
                                window.open('http://instagram.com/jakialpin')
                              }} />
                            </Col>
                          </Item>
                        </div>
                      </div>
                      <Col xs={12} md={2} className='align-middle my-auto pt-3 pt-md-0'>
                        <h1 className="text-center align-middle w-100" style={{ fontSize: '1.75rem', 
                        color: 'white' }}>
                          Dengan
                          </h1>
                      </Col>

                      <div className=' col-xs-12 mt-3 mt-lg-0  col-md-5' data-aos="fade-right" data-aos-duration="1000">
                        <div className='kotak mr-lg-2' style={{ backgroundColor: cmain }}>
                          <Item>
                            <h1 style={{ fontSize: '1.75rem', color: '#F0C04F',lineHeight:1.2 }} className="w-100 text-center">
                            Karina Novita <br/>Sari Setiawan
                            </h1>
                          </Item>
                          <Item>
                            <Col xs={6}>
                              <img src={gambar('https://i.ibb.co/47Ft9s4/cewek.png')} 
                              className='img-fluid w-100 rounded' />
                            </Col>
                          </Item>

                          <Item>
                            <p className='text-center' style={{ fontSize: '1rem' }}>
                              Putri Dari<br />
                              Bapak Ir. Ketut Sumbrana <br />
                              dan<br />
                              Ibu Lilik Windi Astuti (almh.)<br />
                            </p>
                          </Item>
                          <Item>
                            <Col xs={3}>
                              <img src={logoig} className='img-fluid w-100 btn p-0' onClick={() => {
                                window.open('http://instagram.com/karinavitasr')
                              }} />
                            </Col>
                          </Item>
                        </div>
                      </div>
                    </Item>
                  </Col>
                </Item>
              </Container>


              <Container id='sectiongold27'  fluid>
                <div className='pt-3 ml-3 mr-3'>
                  <Item>
                    <Col xs={4} lg={2}>
                      <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                    </Col>
                  </Item>
                  <Item>
                    <Col xs={12} md={6} className='text-center py-3' style={{ color: '#F0C04F',
                     backgroundColor: cmain }}>
                      <div data-aos='fade-left' data-aos-duration="1000">
                        <Item>
                          <h1 style={{ color: '#F0C04F' }}>
                            Akad Nikah
                           </h1>
                        </Item>
                        <Item>
                          <p className='bold italic' style={{ color: '#F0C04F' }}>
                            31 Desember 2020</p><br />
                        </Item>
                        <Item>
                          <p className='mt-2' style={{ color: '#F0C04F' }}>
                            <b>
                            08:00-09:30
                            </b>
                          </p>
                          <br/>
                        </Item>
                        <Item>
                          <p className='italic' style={{ color: '#F0C04F' }}>
                            <b>Swiss-Bel inn hotel Manyar Surabaya </b><br />
                            Jl. Manyar Kertoarjo No.100, Manyar Sabrangan, 
                            <br/>Kec. Mulyorejo, Kota SBY, Jawa Timur 60116
                                </p>
                        </Item>
                        
                      </div>
                      <div data-aos={`fade-left`} data-aos-duration="1000" className='pt-4'>
                        <Item>
                          <h1 style={{ color: '#F0C04F' }}>
                            Resepsi
                          </h1>
                        </Item>
                        <Item>
                          <p className='bold italic' style={{ color: '#F0C04F' }}>
                            31 Desember 2020
                          </p>
                          <br />
                        </Item>
                        <Item>
                          <p className='mt-2' style={{ color: '#F0C04F' }}>
                            <b> 10:00-13:00</b>
                          </p><br/>
                        </Item>
                        <Item>
                        <p className='italic' style={{ color: '#F0C04F' }}>
                            <b>Swiss-Bel inn hotel Manyar Surabaya </b><br />
                            Jl. Manyar Kertoarjo No.100, Manyar Sabrangan, <br/>
                            Kec. Mulyorejo, Kota SBY, Jawa Timur 60116
                                </p>
                        </Item>
                        
                      </div>
                    </Col>
                  </Item>
                  <Item>
                    <div data-aos="zoom-in" data-aos-duration="1000"
                      className='col-6 col-lg-4 mt-3 button text-center btn p-0'
                  
                      onClick={() => {
                        window.open('https://goo.gl/maps/AbUZexoh9ogRsjsp6')
                      }}>
                      Google Maps
                    </div>
                  </Item>
                  <Item>
                    <div data-aos="zoom-in" data-aos-duration="1000"
                     className='col-6 col-lg-4 mt-3 button text-center smaller btn p-0' onClick={() => {
                      window.open('https://calendar.google.com/event?action=TEMPLATE&tmeid=NnU3OGR2aHNrZWRzbzEzZGt1NTMxcmdjMWIgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com')
                    }} >
                      Tambahkan ke Google Calendar
                </div>
                  </Item>
                  <Item>
                <Col xs={12} md={8} className='px-2'>
                  <Alert variant='warning text-justify' className='f-14  mt-3 text-center'
                    style={{ backgroundColor: 'rgb(225, 231, 246)', border: '2px solid rgb(127, 138, 169)', color: 'rgb(127, 138, 169)', fontSize: '14px' }}>
                    <p ><b>Dengan memohon rahmat dan ridho Allah Subhanahu Wa Ta'ala, kami mengundang
                    Bapak/ Ibu / Saudara/i, untuk menghadiri Resepsi Pernikahan kami. Dengan tetap mematuhi protokoler kesehatan
                                            </b>
                      <br /><br />
                                            --
                                            </p>
                    <Item>
                      <Col xs={6} md={3}>
                        <div className='justify-content-center'>
                          <img src={gambar('https://i.ibb.co/nCYXNPV/14.png', 90)} className='w-100 img-fluid' />
                        </div>
                        <p >
                          Selalu memakai masker selama acara berlangsung
                                                </p>
                      </Col>
                      <Col xs={6} md={3}>
                        <div className='justify-content-center'>
                          <img src={gambar('https://i.ibb.co/9gfP42Z/11.png', 90)} className='w-100 img-fluid' />
                        </div>
                        <p className='text-center'>
                          Tidak bersalaman, dapat menggantinya dengan salaman jarak jauh
                                                </p>
                      </Col>
                      <Col xs={6} md={3}>
                        <div className='justify-content-center'>
                          <img src={gambar('https://i.ibb.co/s2wYtW9/12.png', 90)} className='w-100 img-fluid' />
                        </div>
                        <p className='text-center'>
                          Mencuci tangan sebelum dan sesudah memasuki lokasi acara
                                                </p>
                      </Col>
                      <Col xs={6} md={3}>
                        <div className='justify-content-center'>
                          <img src={gambar('https://i.ibb.co/t8mpzrN/13.png', 90)} className='w-100 img-fluid' />
                        </div>
                        <p className='text-center'>
                          Tidak berkerumun, dengan menjaga jarak satu sama lain ± 2 meter
                                                </p>
                      </Col>
                      <Alert variant='info text-center' className='f-14  mt-3'>
                        <p>
                          Harap datang sesuai dengan waktu yang telah ditentukan
                                            </p>
                      </Alert>
                    </Item>
                  </Alert>
                </Col>
              </Item>

                  <Item>
                    <div data-aos="fade-left" data-aos-duration="1000" className='col-10 col-lg-8 kotak' style={{ backgroundColor: '#F0C04F' }}>
                      <Item>
                        <div className='item'>
                          <Item>
                            <div>
                              {days}
                            </div>
                          </Item>
                          <Item>
                            <span>
                              Hari
                      </span>
                          </Item>
                        </div>
                        <div className='dot'>:</div>
                        <div className='item'>
                          <Item>
                            <div>
                              {hours}
                            </div>
                          </Item>
                          <Item>
                            <span>
                              Jam
                      </span>
                          </Item>
                        </div>
                        <div className='dot'>:</div>
                        <div className='item'>
                          <Item>
                            <div >
                              {minutes}
                            </div>
                          </Item>
                          <Item>
                            <span>
                              Mnt
                      </span>
                          </Item>
                        </div>
                        <div className='dot' >:</div>
                        <div className='item'>
                          <Item>
                            <div>
                              {seconds}
                            </div>
                          </Item>
                          <Item>
                            <span>
                              Dtk
                      </span>
                          </Item>
                        </div>
                      </Item>

                    </div>
                  </Item>
                </div></Container>
              <Container className='mt-3' data-aos="fade-right" data-aos-duration="1000" style={{ backgroundColor: '#1F467A' }}>
                <Slider slide={slider} />
              </Container>
              <Container id='sectiongold28' >
                <Col xs={12} className='pt-5 pl-md-5 pr-md-5 text-center'>
                  <Alert variant='warning'>
                    <p style={{ fontSize: '14px' }}>
                      Ya Allah<br />
Tentramkanlah antara kedua mempelai ini<br />
Sebagaimana Engkau telah menentramkan antara<br />
Nabi Adam dan Hawa<br />
Nabi Yusuf dan Julaikha<br />
Junjungan kami Nabi Muhammad SAW dan Khadijah Al Kubra<br /><br />

Ya Allah<br />
Panjangkanlah umur kami<br />
Terangilah hati kami<br />
Teguhkanlah iman kami<br />
Baguskanlah amal perbuatan kami<br />
Lapangkanlah rizki kami<br />
Kabulkanlah hajat kami<br />
Guna mendapatkan ridho-Mu dan kebajikan<br /><br />

Semoga Allah<br />
Melimpahkan sholawat dan salam<br />
Atas junjungan kami<br />
Nabi Muhammad SAW, keluarga<br />
Dan para sahabatnya<br />
                    </p>

                  </Alert>
                </Col>
                <div className='pt-3 mt-4 mt-lg-5'>
                  <Item>
                    <Col xs={4} lg={2}>
                      <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                    </Col>
                  </Item>
                  <Item>
                    <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="fade-left">
                      <Item>
                        <h1 style={{ color: '#F0C04F' }}>
                          Kirim  Ucapan
                        </h1>
                      </Item>
                      <Item>
                        <form className="col-12 w-100 jefri">
                          <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Nama" name='nama' defaultValue={query ? query : ''} />
                          <input ref={this.alamat} type='text' className="col-12 w-100 text-center" placeholder="Alamat" name='alamat' />
                          <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Pesan" name='pesan' />
                          <Item>
                              <div id="formradio">
                                <div class="custom_radio row justify-content-center">
                                  <div onClick={() => {
                                    this.setState({ hadir: true })
                                  }
                                  }>
                                    <input type="radio" id="featured-1" name="featured" checked={hadir ? true : false} />
                                    <label for="featured-1">Hadir</label>
                                  </div>
                                  <div onClick={() => {
                                    this.setState({ hadir: false })
                                  }
                                  } className="pl-5">
                                    <input type="radio" id="featured-2" name="featured" checked={hadir ? false : true} />
                                    <label for="featured-2"

                                    >Tidak Hadir</label>
                                  </div>
                                  
                                </div>
                              </div>
                            </Item>

                          <Item>
                            <Col xs={12} className=''>
                              {
                                submitted == true ? (
                                  <Alert variant='success' style={{ fontSize: '16px' }}>
                                    Pesan anda sudah disampaikan
                                  </Alert>) : (submitted === false ? (
                                    <Alert variant='danger' style={{ fontSize: '16px' }}>
                                      {
                                        err.map(val => {
                                          return (
                                            <li>{val}</li>
                                          )
                                        })
                                      }

                                    </Alert>
                                  ) : false)
                              }

                            </Col>
                          </Item>
                          <Item>
                            <div  className='col-6 button rounded btn p-0' 
                            style={{ backgroundColor: '#F0C04F' }} 
                            onClick={() => this.handleSubmit()}> Kirim </div>
                          </Item>
                        </form>
                      </Item>
                    </div>
                  </Item>
                  <h2 data-aos="fade-right">Dzaky & Karina</h2>
                </div>
              </Container>
             
              <Container className='text-center pb-5' id='sectiongold29'>
                <Item>
                  <h1 >
                    Possible Wedding
              </h1>
                </Item>
                <Item>
                  <h2  >
                    Digital Invitation
              </h2>
                </Item>
                <Item>
                  <Col md={8}>
                    <Item>
                      <div className='col-2 col-md-1 btn p-0'>
                        <img src={logoig} className='img-fluid w-100 '
                        />
                      </div>
                    </Item>
                  </Col>
                </Item>
              </Container>
         
            </div>
          </div>
        </div>
      </>


    )
  }
}